<template>
  <base-projects
    :projects="projectsWithActiveTime"
    :is-loading="!projectsWithActiveTime.length && isLoading.projects"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import BaseProjects from './BaseProjects';

export default {
  name: 'ManageProjects',
  components: { BaseProjects },
  computed: {
    ...mapGetters([
      'projectsWithActiveTime',
      'isLoading'
    ])
  }
};
</script>
